<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main">
            <div>
                <p v-if="applyStatus == 3" class="a-c-error a-fw-700">驳回原因：{{ otherInfo.reviewRemark || '-' }}</p>
                <el-table :border='true' :data="tableData" style="width: 100%;">
                    <el-table-column label="用户姓名" >
                        <template slot-scope="{ row }">
                            <span>{{ row.userName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="用户手机号" >
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="近30天平均有功功率(W)" >
                        <template slot-scope="{ row }">
                            <span>{{ row.lastThirtyDayPower || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="近30天充电次数" >
                        <template slot-scope="{ row }">
                            <span>{{ row.lastThirtyDayOrderTimes || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请时间" >
                        <template slot-scope="{ row }">
                            <span>{{ applyTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" >
                        <template slot-scope="{ row }">
                            <span>{{ applyStatus | initDic(statusDic) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="a-flex-rfsfs a-fs-14 a-ptb-20 a-fw-700 a-c-blue">
                    <span>备注：{{ otherInfo.applyRemark || '-' }}</span>
                </div>
                <div class="a-flex-rfsc">
                    <div class="a-flex-1 a-flex-cfsfs">
                        <span class="a-fs-16 a-fw-700 a-ptb-20">修改前车辆信息</span>
                        <el-form :model="originForm" ref="originForm" label-width="145px" label-position="left">
                            <el-form-item label="车辆类型" prop="name" >
                                <span>{{ originForm.type | initDic(typeDic) }}</span>
                            </el-form-item>
                            <el-form-item label="品牌" prop="name" >
                                <span>{{ originForm.brand || '-' }}</span>
                            </el-form-item>
                            <el-form-item label="电瓶规格" prop="name" >
                                <span>{{ originForm.batteryVoltage | initDic(batteryVoltageDic) }}</span>
                            </el-form-item>
                            <el-form-item label="车牌号" prop="name" >
                                <span>{{ originForm.carNumber || '-' }}</span>
                            </el-form-item>
                            <el-form-item label="车辆图片" prop="name" >
                                <div class="a-flex-cfsfs a-flex-wrap">
                                    <el-image
                                        style="width: 100px; height: 100px; border-radius: 8rpx; margin-right: 10px"
                                        :preview-src-list='[originForm.imagePath]'
                                        :src="originForm.imagePath"></el-image>
                                </div>
                            </el-form-item>
                            <el-form-item label="充电器图片" prop="name" >
                                <div class="a-flex-cfsfs a-flex-wrap">
                                    <el-image
                                        style="width: 100px; height: 100px; border-radius: 8rpx; margin-right: 10px"
                                        :preview-src-list='[originForm.imagePaths]'
                                        :src="originForm.imagePaths"></el-image>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="a-flex-1 a-flex-cfsfs">
                        <span class="a-fs-16 a-fw-700 a-ptb-20">申请审批车辆信息</span>
                        <el-form :model="auditForm" ref="auditForm" label-width="145px" label-position="left">
                            <el-form-item label="车辆类型" prop="name" >
                                <span>{{ auditForm.type | initDic(typeDic) }}</span>
                            </el-form-item>
                            <el-form-item label="品牌" prop="name" >
                                <span>{{ auditForm.brand || '-' }}</span>
                            </el-form-item>
                            <el-form-item label="电瓶规格" prop="name" >
                                <span>{{ auditForm.batteryVoltage | initDic(batteryVoltageDic) }}</span>
                            </el-form-item>
                            <el-form-item label="车牌号" prop="name" >
                                <span>{{ auditForm.carNumber || '-' }}</span>
                            </el-form-item>
                            <el-form-item label="车辆图片" prop="name" >
                                <div class="a-flex-cfsfs a-flex-wrap">
                                    <el-image
                                        style="width: 100px; height: 100px; border-radius: 8rpx; margin-right: 10px"
                                        :preview-src-list='[auditForm.imagePath]'
                                        :src="auditForm.imagePath"></el-image>
                                </div>
                            </el-form-item>
                            <el-form-item label="充电器图片" prop="name" >
                                <div class="a-flex-cfsfs a-flex-wrap">
                                    <el-image
                                        style="width: 100px; height: 100px; border-radius: 8rpx; margin-right: 10px"
                                        :preview-src-list='[auditForm.imagePaths]'
                                        :src="auditForm.imagePaths"></el-image>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div style="height:100px"></div>
            </div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit('pass')" v-if="applyStatus == 1">通过</el-button>
                <el-button type="danger" class="a-ml-24 a-mt-15" @click="submit('reject')" v-if="applyStatus == 1">驳回</el-button>
                <el-button class="a-ml-24 a-mt-15" @click="cancelSubmit">返回</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import util from '@/utils/util'
export default {
    data () {
        return {
            util: util,
            id: '',
            targetUserId: '',
            applyStatus: '',
            applyTime: '',
            tableData: [],
            otherInfo: '',
            auditForm: {

            },
            originForm: {

            },
            typeDic: [],//车辆类型字典
            batteryVoltageDic: [],//车辆电瓶规格字典
            statusDic: [],
        }
    },
    created () {
        this.$getDic('userBikeType','select').then(res=>{ this.typeDic = res; })
        this.$getDic('userBikeBattery','select').then(res=>{ this.batteryVoltageDic = res; })
        this.$getDic('userBikeApplyStatus','select').then(res=>{ this.statusDic = res; })
    },
    mounted () {
        this.id = this.$route.query.id
        this.targetUserId = this.$route.query.targetUserId
        this.applyTime = this.$route.query.applyTime
        this.applyStatus = this.$route.query.applyStatus
        if(this.id) {
            this.getDetail()
        }
        if(this.targetUserId) {
            this.getUser30()
        }
        // this.$getDic('chargePayeeType','select').then(res=>{ this.chargePayeeTypeDic = res; })
    },
    methods:{
        getDetail () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getUserBikeApplyDetail,
                method: "post",
                params: {
                    id: this.id
                }
            })
            .then(res=>{
                if (res.result.code == 0) {
                    this.auditForm = JSON.parse(res.result.data.newBikeInfo)
                    this.originForm = JSON.parse(res.result.data.oldBikeInfo)
                    this.otherInfo = {
                        ...res.result.data
                    }
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{
                console.log(err);
            })
        },
        // 获取用户30天充电信息
        getUser30 () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getUserPowerInfo,
                method: "post",
                params: {
                    userId: this.targetUserId
                }
            })
            .then(res=>{
                if (res.result.code == 0) {
                   this.tableData = [res.result.data]
                } else {
                    this.$message.error(res.result.message)
                }
            })
            .catch(err=>{
                console.log(err);
            })
        },
        submit (type) {
            if(type == 'pass') {
                this.submitPass()
            }else {
                this.submitReject()
            }
        },
        submitPass () {
            this.$confirm('是否确认通过审批？','温馨提示')
            .then(_=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.applyUserBike,
                    method: "post",
                    params: {
                        id: this.id,
                        status: 2
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.$router.back()
                        this.$store.dispatch("delView", this.$route);
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            })
            .catch(_=>{})
        },
        submitReject () {
            this.$prompt('请输入驳回原因', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator: (val)=>{
                    if(!val){
                        return '请输入驳回原因'
                    }
                    if(val.length > 100) {
                        return '驳回原因不得多于100字'
                    }
                }
            }).then(({ value }) => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.applyUserBike,
                    method: "post",
                    params: {
                        id: this.id,
                        remark: value,
                        status: 3
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.$message.success('操作成功')
                        this.$router.back()
                        this.$store.dispatch("delView", this.$route);
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            }).catch(() => {
      
            });
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
    },
}
</script>

<style lang="scss" scoped>

</style>